import React from "react"
import Script from "react-load-script"
import PrismicRepoSettingsContext from "../../context/settings"

export const PrismicScript = () => {
  const settings = React.useContext(PrismicRepoSettingsContext)
  return (
    <Script
      url={`https://static.cdn.prismic.io/prismic.js?repo=${settings.repoName}&new=true`}
      async
      defer
    />
  )
}
