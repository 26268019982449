import { Placeholders } from "../fields/RichTextField/types"

const matchTagsRegex = (tags: string[]) =>
  new RegExp(`<(${tags.map((x) => `${x}|/${x}`).join("|")})[^>]{0,}>`, "ig")

export const cleanHtml = (value: string, invalidTags: string[]) => {
  return value.replace(matchTagsRegex(invalidTags), "")
}

export const replacePlaceholders = (
  value: string,
  placeholders: Placeholders
) => {
  let text = value
  for (const key in placeholders) {
    text = text.replace(`{${key.toUpperCase()}}`, placeholders[key] ?? "")
  }
  return text
}
