import React from "react"
import { Hidden } from "@material-ui/core"
import { useDefaultMobileBreakpoint } from "../../../common/layout/breakpoints"

interface Props {
  children: any
  implementation?: "css" | "js"
}

const MobileOnly = ({ children, implementation }: Props) => {
  const breakpoint = useDefaultMobileBreakpoint()
  return (
    <Hidden
      implementation={implementation}
      smUp={breakpoint === "xs"}
      mdUp={breakpoint === "sm"}
      lgUp={breakpoint === "md"}
    >
      {children}
    </Hidden>
  )
}

MobileOnly.defaultProps = {
  implementation: "css",
}

export default MobileOnly
