import { LinkType, LinkValue } from "../../../common/fields/LinkField"

export interface PrismicLinkData {
  id: string | null | undefined
  url: string | null | undefined
  link_type: string | null | undefined // eslint-disable-line
  target: string | null | undefined
}

export const toLinkData = (
  prismicLink: PrismicLinkData | null | undefined,
  target?: string
): LinkValue | undefined =>
  prismicLink
    ? {
        type: (prismicLink?.link_type?.toLowerCase() as LinkType) ?? undefined,
        pageId: prismicLink?.id ?? undefined,
        target: target ?? prismicLink?.target ?? undefined,
        url: prismicLink?.url ?? undefined,
      }
    : undefined
