import React from "react"
import { RichTextRenderingProps } from "./types"

export type RichTextProcessor = (
  richTextProps: RichTextRenderingProps
) => React.ReactNode

export interface RichTextProcessorsContext {
  default: RichTextProcessor
}

const emptyProcessor: RichTextProcessor = () => {
  throw new Error("No rich text processor has been defined")
}

export const RichTextProcessors = React.createContext<
  RichTextProcessorsContext
>({
  default: emptyProcessor,
})

export const useRichTextProcessors = () => React.useContext(RichTextProcessors)
export const useDefaultRichTextProcessor = () => useRichTextProcessors().default
