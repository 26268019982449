import React from "react"
import { useDefaultRichTextProcessor } from "./processors"
import { RichTextRenderingProps } from "./types"

export interface RichTextProps extends RichTextRenderingProps {}

const RichTextField = (props: RichTextProps) => {
  const processor = useDefaultRichTextProcessor()
  return <>{processor(props)}</>
}

export default RichTextField
