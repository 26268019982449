import { WebsiteThemeQuery_allPrismicWebsiteTheme_nodes as WebsiteThemeNode } from "../../types/theme"
import { toMap } from "../../../../common/utils/arrayUtils"
import {
  AppBackgrounds,
  AppBreakpoint,
  AppBreakpoints,
  AppComponents,
  AppCustomColorsMap,
  AppPalette,
  AppStyles,
  AppTheme,
} from "../../../../common/context/theme/types"

const DEFAULT_MOBILE_BREAKPOINT = "sm"

interface MapColor {
  name: string
  value: string
}

class ColorsMap implements AppCustomColorsMap {
  private map = new Map<string, MapColor>()

  constructor(colors: MapColor[]) {
    this.map = toMap(colors, (x: any) => x.name)
  }

  getColor = (id: string) => {
    const color = this.map.get(id)
    if (!color) {
      throw new Error(`Color ${id} is not defined`)
    }
    return color.value
  }
}

const getColorsMap = (node: WebsiteThemeNode): AppCustomColorsMap =>
  new ColorsMap(
    node.data?.custom_colors?.map((x) => ({
      name: x?.color_name ?? "",
      value: x?.color_value ?? "",
    })) ?? []
  )

const getStyles = (node: WebsiteThemeNode): AppStyles => ({
  borders: {
    borderRadius: {
      default: node.data?.border_radius_default
        ? parseInt(node.data.border_radius_default.trim())
        : undefined,
      large: node.data?.border_radius_large
        ? parseInt(node.data?.border_radius_large.trim())
        : undefined,
      medium: node.data?.border_radius_medium
        ? parseInt(node.data?.border_radius_medium.trim())
        : undefined,
    },
  },
  shadows: {
    disabled: node.data?.box_shadow_disabled ?? false,
    style: node.data?.box_shadow_override ?? undefined,
  },
})

const getBreakpoints = (node: WebsiteThemeNode): AppBreakpoints => ({
  mobileBreakpoint: (node.data?.mobile_breakpoint ??
    DEFAULT_MOBILE_BREAKPOINT) as AppBreakpoint,
})

const getBackground = (node: WebsiteThemeNode): AppBackgrounds => ({
  dark: {
    color: node.data?.dark_background_color ?? undefined,
    image: node.data?.dark_background_image?.url ?? undefined,
  },
  default: {
    color: node.data?.default_background_color ?? undefined,
    image: node.data?.default_background_image?.url ?? undefined,
  },
})

const getComponents = (node: WebsiteThemeNode): AppComponents => ({
  button: {
    default: {
      borderWidth: node.data?.buttons_default_border_width ?? undefined,
      boxShadow: node.data?.buttons_default_box_shadow ?? undefined,
      fontWeight: node.data?.buttons_default_font_weight ?? undefined,
      letterSpacing: node.data?.buttons_default_letter_spacing ?? undefined,
      padding: {
        bottom: node.data?.buttons_default_padding_bottom ?? undefined,
        left: node.data?.buttons_default_padding_left ?? undefined,
        right: node.data?.buttons_default_padding_right ?? undefined,
        top: node.data?.buttons_default_padding_top ?? undefined,
      },
    },
    contained: {}, // TODO
    outlined: {}, // TODO
  },
})

const isDefaultTheme = (node: WebsiteThemeNode) =>
  node.data?.default_theme ?? false

const getThemeName = (node: WebsiteThemeNode) => node.data?.theme_name ?? ""

const getFonts = (node: WebsiteThemeNode) => ({
  styles: {
    defaults: {
      fontFamily: node.data?.default_font_family ?? undefined,
      fontWeight: node.data?.default_font_weight ?? undefined,
    },
    textColors: {
      disabled: node.data?.text_disabled_color ?? undefined,
      hint: node.data?.text_hint_color ?? undefined,
      primary: node.data?.text_primary_color ?? undefined,
      secondary: node.data?.text_secondary_color ?? undefined,
    },
    importUrls: node.data?.font_import_urls
      ?.map((x) => x?.font_import_url ?? "")
      .filter((x) => x.trim() !== ""),
  },
  typography: {
    h1: {
      color: node.data?.h1_font_color ?? undefined,
      letterSpacing: node.data?.h1_letter_spacing ?? undefined,
      lineHeight: node.data?.h1_line_height ?? undefined,
      size: node.data?.h1_font_size ?? undefined,
      weight: node.data?.h1_font_weight ?? undefined,
    },
    h2: {
      color: node.data?.h2_font_color ?? undefined,
      letterSpacing: node.data?.h2_letter_spacing ?? undefined,
      lineHeight: node.data?.h2_line_height ?? undefined,
      size: node.data?.h2_font_size ?? undefined,
      weight: node.data?.h2_font_weight ?? undefined,
    },
    h3: {
      color: node.data?.h3_font_color ?? undefined,
      letterSpacing: node.data?.h3_letter_spacing ?? undefined,
      lineHeight: node.data?.h3_line_height ?? undefined,
      size: node.data?.h3_font_size ?? undefined,
      weight: node.data?.h3_font_weight ?? undefined,
    },
    h4: {
      color: node.data?.h4_font_color ?? undefined,
      letterSpacing: node.data?.h4_letter_spacing ?? undefined,
      lineHeight: node.data?.h4_line_height ?? undefined,
      size: node.data?.h4_font_size ?? undefined,
      weight: node.data?.h4_font_weight ?? undefined,
    },
    h5: {
      color: node.data?.h5_font_color ?? undefined,
      letterSpacing: node.data?.h5_letter_spacing ?? undefined,
      lineHeight: node.data?.h5_line_height ?? undefined,
      size: node.data?.h5_font_size ?? undefined,
      weight: node.data?.h5_font_weight ?? undefined,
    },
    h6: {
      color: node.data?.h6_font_color ?? undefined,
      letterSpacing: node.data?.h6_letter_spacing ?? undefined,
      lineHeight: node.data?.h6_line_height ?? undefined,
      size: node.data?.h6_font_size ?? undefined,
      weight: node.data?.h6_font_weight ?? undefined,
    },
    subtitle1: {
      color: node.data?.subtitle1_font_color ?? undefined,
      letterSpacing: node.data?.subtitle1_letter_spacing ?? undefined,
      lineHeight: node.data?.subtitle1_line_height ?? undefined,
      size: node.data?.subtitle1_font_size ?? undefined,
      weight: node.data?.subtitle1_font_weight ?? undefined,
    },
    subtitle2: {
      color: node.data?.subtitle2_font_color ?? undefined,
      letterSpacing: node.data?.subtitle2_letter_spacing ?? undefined,
      lineHeight: node.data?.subtitle2_line_height ?? undefined,
      size: node.data?.subtitle2_font_size ?? undefined,
      weight: node.data?.subtitle2_font_weight ?? undefined,
    },
    body1: {
      color: node.data?.body1_font_color ?? undefined,
      letterSpacing: node.data?.body1_letter_spacing ?? undefined,
      lineHeight: node.data?.body1_line_height ?? undefined,
      size: node.data?.body1_font_size ?? undefined,
      weight: node.data?.body1_font_weight ?? undefined,
    },
    body2: {
      color: node.data?.body2_font_color ?? undefined,
      letterSpacing: node.data?.body2_letter_spacing ?? undefined,
      lineHeight: node.data?.body2_line_height ?? undefined,
      size: node.data?.body2_font_size ?? undefined,
      weight: node.data?.body2_font_weight ?? undefined,
    },
    overline: {
      color: node.data?.overline_font_color ?? undefined,
      letterSpacing: node.data?.overline_letter_spacing ?? undefined,
      lineHeight: node.data?.overline_line_height ?? undefined,
      size: node.data?.overline_font_size ?? undefined,
      weight: node.data?.overline_font_weight ?? undefined,
    },
  },
  typographyMobile: {
    h1: {
      color: node.data?.h1_font_color_mobile ?? undefined,
      letterSpacing: node.data?.h1_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.h1_line_height_mobile ?? undefined,
      size: node.data?.h1_font_size_mobile ?? undefined,
      weight: node.data?.h1_font_weight_mobile ?? undefined,
    },
    h2: {
      color: node.data?.h2_font_color_mobile ?? undefined,
      letterSpacing: node.data?.h2_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.h2_line_height_mobile ?? undefined,
      size: node.data?.h2_font_size_mobile ?? undefined,
      weight: node.data?.h2_font_weight_mobile ?? undefined,
    },
    h3: {
      color: node.data?.h3_font_color_mobile ?? undefined,
      letterSpacing: node.data?.h3_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.h3_line_height_mobile ?? undefined,
      size: node.data?.h3_font_size_mobile ?? undefined,
      weight: node.data?.h3_font_weight_mobile ?? undefined,
    },
    h4: {
      color: node.data?.h4_font_color_mobile ?? undefined,
      letterSpacing: node.data?.h4_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.h4_line_height_mobile ?? undefined,
      size: node.data?.h4_font_size_mobile ?? undefined,
      weight: node.data?.h4_font_weight_mobile ?? undefined,
    },
    h5: {
      color: node.data?.h5_font_color_mobile ?? undefined,
      letterSpacing: node.data?.h5_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.h5_line_height_mobile ?? undefined,
      size: node.data?.h5_font_size_mobile ?? undefined,
      weight: node.data?.h5_font_weight_mobile ?? undefined,
    },
    h6: {
      color: node.data?.h6_font_color_mobile ?? undefined,
      letterSpacing: node.data?.h6_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.h6_line_height_mobile ?? undefined,
      size: node.data?.h6_font_size_mobile ?? undefined,
      weight: node.data?.h6_font_weight_mobile ?? undefined,
    },
    subtitle1: {
      color: node.data?.subtitle1_font_color_mobile ?? undefined,
      letterSpacing: node.data?.subtitle1_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.subtitle1_line_height_mobile ?? undefined,
      size: node.data?.subtitle1_font_size_mobile ?? undefined,
      weight: node.data?.subtitle1_font_weight_mobile ?? undefined,
    },
    subtitle2: {
      color: node.data?.subtitle2_font_color_mobile ?? undefined,
      letterSpacing: node.data?.subtitle2_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.subtitle2_line_height_mobile ?? undefined,
      size: node.data?.subtitle2_font_size_mobile ?? undefined,
      weight: node.data?.subtitle2_font_weight_mobile ?? undefined,
    },
    body1: {
      color: node.data?.body1_font_color_mobile ?? undefined,
      letterSpacing: node.data?.body1_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.body1_line_height_mobile ?? undefined,
      size: node.data?.body1_font_size_mobile ?? undefined,
      weight: node.data?.body1_font_weight_mobile ?? undefined,
    },
    body2: {
      color: node.data?.body2_font_color_mobile ?? undefined,
      letterSpacing: node.data?.body2_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.body2_line_height_mobile ?? undefined,
      size: node.data?.body2_font_size_mobile ?? undefined,
      weight: node.data?.body2_font_weight_mobile ?? undefined,
    },
    overline: {
      color: node.data?.overline_font_color_mobile ?? undefined,
      letterSpacing: node.data?.overline_letter_spacing_mobile ?? undefined,
      lineHeight: node.data?.overline_line_height_mobile ?? undefined,
      size: node.data?.overline_font_size_mobile ?? undefined,
      weight: node.data?.overline_font_weight_mobile ?? undefined,
    },
  },
})

const getPalette = (node: WebsiteThemeNode): AppPalette => ({
  action: {
    active: node.data?.action_active_color ?? undefined,
    disabled: node.data?.action_disabled_color ?? undefined,
    focus: node.data?.action_focus_color ?? undefined,
    hover: node.data?.action_hover_color ?? undefined,
    selected: node.data?.action_selected_color ?? undefined,
  },
  primary: {
    main: node.data?.primary_main ?? undefined,
    dark: node.data?.primary_dark ?? undefined,
    light: node.data?.primary_light ?? undefined,
  },
  secondary: {
    main: node.data?.secondary_main ?? undefined,
    dark: node.data?.secondary_dark ?? undefined,
    light: node.data?.secondary_light ?? undefined,
  },
  error: {
    main: node.data?.error_main ?? undefined,
    dark: node.data?.error_dark ?? undefined,
    light: node.data?.error_light ?? undefined,
  },
  success: {
    main: node.data?.success_main ?? undefined,
    dark: node.data?.success_dark ?? undefined,
    light: node.data?.success_light ?? undefined,
  },
  warn: {
    main: node.data?.warning_main ?? undefined,
    dark: node.data?.warning_dark ?? undefined,
    light: node.data?.warning_light ?? undefined,
  },
  info: {
    main: node.data?.info_main ?? undefined,
    dark: node.data?.info_dark ?? undefined,
    light: node.data?.info_light ?? undefined,
  },
  custom: getColorsMap(node),
})

export const toAppTheme = (node: WebsiteThemeNode): AppTheme => ({
  name: getThemeName(node),
  default: isDefaultTheme(node),
  background: getBackground(node),
  components: getComponents(node),
  font: getFonts(node),
  palette: getPalette(node),
  styles: getStyles(node),
  breakpoints: getBreakpoints(node),
})
