import React from "react"
import RootProviders from "../root/common/providers"
import { PrismicProviders } from "../root/contents/prismic"
import { MuiProviders } from "../root/ui/mui"
import { ContentsProvider } from "./providers/contentProviders"

interface Params {
  element: any
}

export const wrapRootElement = ({ element }: Params) => {
  return (
    <RootProviders>
      <PrismicProviders>
        <MuiProviders>
          <ContentsProvider>{element}</ContentsProvider>
        </MuiProviders>
      </PrismicProviders>
    </RootProviders>
  )
}

export * from "../root/contents/prismic/fragments"
export * from "./fragments"
