import { AppTheme } from "../context/theme/types"
import { ThemeColor, ThemeColorVariant } from "./types"

export const getPrimaryColor = (
  theme: AppTheme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.primary?.main
    case "dark":
      return theme.palette.primary?.dark
    case "light":
      return theme.palette.primary?.light
  }
}

export const getSecondaryColor = (
  theme: AppTheme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.secondary?.main
    case "dark":
      return theme.palette.secondary?.dark
    case "light":
      return theme.palette.secondary?.light
  }
}

export const getInfoColor = (
  theme: AppTheme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.info?.main
    case "dark":
      return theme.palette.info?.dark
    case "light":
      return theme.palette.info?.light
  }
}

export const getSuccessColor = (
  theme: AppTheme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.success?.main
    case "dark":
      return theme.palette.success?.dark
    case "light":
      return theme.palette.success?.light
  }
}

export const getWarningColor = (
  theme: AppTheme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.warn?.main
    case "dark":
      return theme.palette.warn?.dark
    case "light":
      return theme.palette.warn?.light
  }
}

export const getErrorColor = (
  theme: AppTheme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.error?.main
    case "dark":
      return theme.palette.error?.dark
    case "light":
      return theme.palette.error?.light
  }
}

export const getColor = (
  theme: AppTheme,
  color: ThemeColor,
  colorVariant = "main" as ThemeColorVariant
) => {
  switch (color) {
    case "primary":
      return getPrimaryColor(theme, colorVariant)
    case "secondary":
      return getSecondaryColor(theme, colorVariant)
    case "info":
      return getInfoColor(theme, colorVariant)
    case "success":
      return getSuccessColor(theme, colorVariant)
    case "warning":
      return getWarningColor(theme, colorVariant)
    case "error":
      return getErrorColor(theme, colorVariant)
    case "neutral":
      return theme.palette.action?.active
    case "disabled":
      return theme.palette.action?.disabled
    case "white":
      return "#fff"
    case "black":
      return "#000"
    case "inherit":
      return "inherit"
    case "transparent":
      return "rgba(0,0,0,0)"
  }
}
