import React from "react"
import { Link } from "gatsby"
import { SitePagesContext } from "../../context"

export type LinkType = "document" | "media" | "web"

export interface LinkValue {
  pageId?: string
  url?: string
  target?: string
  type: LinkType
}

export interface LinkFieldProps {
  value: LinkValue | undefined
  className?: string
  activeClassName?: string
  children?: React.ReactNode
  onClick?: () => void
}

const InternalLink = ({
  value,
  className,
  activeClassName,
  children,
  onClick,
}: LinkFieldProps) => {
  const sitePages = React.useContext(SitePagesContext)

  if (!value?.pageId) {
    return <></>
  }

  const path = sitePages.getPagePath(value.pageId)
  return (
    <>
      {path ? (
        <Link
          to={path}
          className={className}
          activeClassName={activeClassName}
          onClick={onClick}
        >
          {children}
        </Link>
      ) : undefined}
    </>
  )
}
const ExternalLink = ({
  value,
  className,
  children,
  onClick,
}: LinkFieldProps) => {
  if (!value?.url) {
    return <></>
  }

  return (
    <a
      className={className}
      href={value.url}
      target={value.target}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

const LinkField = ({
  value,
  className,
  activeClassName,
  children,
  onClick,
}: LinkFieldProps) => {
  if (!value) {
    return <></>
  }
  switch (value.type) {
    case "document":
      return (
        <InternalLink
          value={value}
          className={className}
          activeClassName={activeClassName}
          onClick={onClick}
        >
          {children}
        </InternalLink>
      )
    case "media":
    case "web":
      return (
        <ExternalLink value={value} className={className} onClick={onClick}>
          {children}
        </ExternalLink>
      )
    default:
      return <></>
  }
}

export default LinkField
