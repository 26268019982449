import { AppTheme } from "../context/theme/types"
import { FontWeight, TextVariant } from "./types"

export const getTextFontSize = (theme: AppTheme, size: TextVariant) => {
  switch (size) {
    case "body1":
      return theme.font.typography.body1.size
    case "body2":
      return theme.font.typography.body2.size
    case "h1":
      return theme.font.typography.h1.size
    case "h2":
      return theme.font.typography.h2.size
    case "h3":
      return theme.font.typography.h3.size
    case "h4":
      return theme.font.typography.h4.size
    case "h5":
      return theme.font.typography.h5.size
    case "subtitle1":
      return theme.font.typography.subtitle1.size
    case "subtitle2":
      return theme.font.typography.subtitle2.size
    case "overline":
      return theme.font.typography.overline.size
    default:
      return theme.font.typography.body1.size
  }
}

export const getTextLineHeight = (theme: AppTheme, size: TextVariant) => {
  switch (size) {
    case "body1":
      return theme.font.typography.body1.lineHeight
    case "body2":
      return theme.font.typography.body2.lineHeight
    case "h1":
      return theme.font.typography.h1.lineHeight
    case "h2":
      return theme.font.typography.h2.lineHeight
    case "h3":
      return theme.font.typography.h3.lineHeight
    case "h4":
      return theme.font.typography.h4.lineHeight
    case "h5":
      return theme.font.typography.h5.lineHeight
    case "subtitle1":
      return theme.font.typography.subtitle1.lineHeight
    case "subtitle2":
      return theme.font.typography.subtitle2.lineHeight
    case "overline":
      return theme.font.typography.overline.lineHeight
    default:
      return theme.font.typography.body1.lineHeight
  }
}

export const getFontWeight = (weight: FontWeight) => {
  switch (weight) {
    case "lightest":
      return 100
    case "light":
      return 300
    case "normal":
      return 500
    case "bold":
      return 700
    case "boldest":
      return 900
  }
}
