import React from "react"
import { PrismicLogosProvider } from "../../features/data"

interface Props {
  children: any
}

export const ContentsProvider = ({ children }: Props) => {
  return <PrismicLogosProvider>{children}</PrismicLogosProvider>
}
