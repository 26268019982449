import React from "react"
import { SEO } from "../../../common/seo"

interface Props {
  node: any
}

const getSeoTitle = (data: any) => {
  if (data?.seo_title?.text) {
    return data?.seo_title?.text
  }

  if (data?.seo_title) {
    return data?.seo_title
  }

  if (data?.title?.text) {
    return data?.title?.text
  }

  if (data?.title) {
    return data?.title
  }

  return ""
}

const getSeoDescription = (data: any) => {
  if (data?.seo_description?.text) {
    return data?.seo_description?.text
  }

  if (data?.seo_description) {
    return data?.seo_description
  }

  return ""
}

const PrismicSEO = ({ node }: Props) => {
  return (
    <SEO
      lang={node.lang as string}
      title={getSeoTitle(node.data)}
      description={getSeoDescription(node.data)}
    />
  )
}

export default PrismicSEO
