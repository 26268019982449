import React from "react"
import { PreviewStoreProvider } from "gatsby-source-prismic"
import { PrismicRepoSettingsProvider } from "../context/settings"
import PrismicScriptImport from "./script"
import { PrismicWebsiteSettingsProvider } from "./settings"
import { PrismicWebsiteThemeProvider } from "./theme"
import { PrismicRichTextProvider } from "../richText"

interface Props {
  children: any
}

export const PrismicProviders = ({ children }: Props) => {
  return (
    <PreviewStoreProvider>
      <PrismicRepoSettingsProvider>
        <PrismicWebsiteSettingsProvider>
          <PrismicWebsiteThemeProvider>
            <PrismicRichTextProvider>
              {children}
              <PrismicScriptImport />
            </PrismicRichTextProvider>
          </PrismicWebsiteThemeProvider>
        </PrismicWebsiteSettingsProvider>
      </PrismicRepoSettingsProvider>
    </PreviewStoreProvider>
  )
}
