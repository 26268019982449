import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import classNames from "classnames"
import React from "react"
import {
  getColor,
  getFontWeight,
  getTextFontSize,
  getTextLineHeight,
  TextProps,
  useDefaultAppTheme,
} from "../../../common"

export interface CustomTypographyProps extends TextProps {
  children: React.ReactNode
}

const useStyles = makeStyles(() => ({
  root: {
    lineHeight: ({ lineHeight }: any) => lineHeight,
    fontSize: ({ fontSize }: any) => fontSize,
    color: ({ color }: any) => color,
    fontWeight: ({ fontWeight }: any) => fontWeight,
  },
  uppercase: {
    textTransform: "uppercase",
  },
  lowercase: {
    textTransform: "lowercase",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textLeft: {
    textAlign: "right",
  },
  ellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}))

export const CustomTypography = ({
  children,
  ...props
}: CustomTypographyProps) => {
  const appTheme = useDefaultAppTheme()
  const classes = useStyles({
    fontSize: props.fontSize
      ? getTextLineHeight(appTheme, props.fontSize)
      : props.fontSizeValue,
    lineHeight: props.lineHeight
      ? getTextFontSize(appTheme, props.lineHeight)
      : props.lineHeightValue,
    color:
      props.colorValue ??
      (props.color
        ? getColor(appTheme, props.color, props.colorVariant)
        : undefined),
    fontWeight: props.weight ? getFontWeight(props.weight) : undefined,
  })
  const typographyProps = {
    component: props.component,
    variant: props.variant,
  }
  return (
    <Typography
      {...typographyProps}
      classes={{
        root: classNames(
          classes.root,
          {
            [classes.capitalize]: props.capitalize,
            [classes.lowercase]: props.lowercase,
            [classes.textCenter]: props.textCenter,
            [classes.textLeft]: props.textLeft,
            [classes.textRight]: props.textRight,
            [classes.ellipsis]: props.ellipsis,
          },
          props.textClassName
        ),
      }}
    >
      {children}
    </Typography>
  )
}

export default CustomTypography
