import React, { createElement } from "react"
import { Elements } from "prismic-richtext"
import { Link } from "gatsby"
import {
  Placeholders,
  TextProps,
  TextRenderer,
  TextVariant,
} from "../../../common"
import { replacePlaceholders } from "../../../common/utils/htmlUtils"

const propsWithUniqueKey = (props = {}, key: any) => {
  return Object.assign(props, { key })
}

export const RawHtml = ({ value }: any) => {
  if (typeof value === "string" && value.indexOf("<") >= 0) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      ></span>
    )
  }

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((x, index) => (
          <RawHtml key={index} value={x} />
        ))}
      </>
    )
  }

  return <>{value}</>
}

const linkStyles = {
  textDecoration: "underline",
}

const renderExternalLink = (element: any, content: any, key: any) => {
  return (
    <a
      href={element.data.url}
      target={element.data.target}
      rel={element.data.target ? "noopener" : undefined}
      key={key}
      style={linkStyles}
    >
      {content}
    </a>
  )
}

const renderInternalLink = (
  element: any,
  content: any,
  linkResolver: any,
  key: any
) => {
  return (
    <Link to={linkResolver(element.data)} key={key} style={linkStyles}>
      {content}
    </Link>
  )
}

const replaceHtmlPlaceholders = (
  content: any,
  placeholders: Placeholders | undefined
): any => {
  if (!placeholders) {
    return content
  }

  if (typeof content === "string") {
    return replacePlaceholders(content, placeholders)
  }

  if (Array.isArray(content)) {
    return content.map((x) => replaceHtmlPlaceholders(x, placeholders))
  }

  return content
}

const serializeLink = (
  linkResolver: any,
  element: any,
  content: any,
  key: any
) => {
  switch (element.data.link_type) {
    case "Web":
      return renderExternalLink(element, content, key)
    case "Document":
      return renderInternalLink(element, content, linkResolver, key)
    default:
      console.error(`Unhandled link type ${element.data.link_type}`)
      return undefined
  }
}

function serializeElement(
  Element: any,
  type: any,
  props: any,
  content: any,
  children: any,
  index: any
) {
  return createElement(Element, {
    key: `element-${type}-${index + 1}`,
    ...props,
    children: children && children.length ? children : undefined,
    ...(type === "image" ? { src: props.url, url: undefined } : null),
  })
}

function serializeSpan(content: any, placeholders: Placeholders | undefined) {
  if (content) {
    const processedContent = replaceHtmlPlaceholders(content, placeholders)
    return processedContent.split("\n").reduce((acc: any, p: any) => {
      if (acc.length === 0) {
        return [p]
      } else {
        const brIndex = (acc.length + 1) / 2 - 1
        const br = createElement("br", propsWithUniqueKey({}, brIndex))
        return acc.concat([br, p])
      }
    }, [])
  } else {
    return null
  }
}

function serializeStandardTag(
  tag: string,
  element: any,
  children: any,
  key: any,
  placeholders: Placeholders | undefined
) {
  const props = element.label
    ? Object.assign({}, { className: element.label })
    : {}
  return createElement(
    tag,
    propsWithUniqueKey(props, key),
    replaceHtmlPlaceholders(children, placeholders)
  )
}

function serializeTypographyElement(
  textRenderer: TextRenderer,
  typographyProps: TextProps,
  element: any,
  children: any,
  key: any
) {
  const props = element.label
    ? Object.assign({}, { className: element.label })
    : {}
  return (
    <React.Fragment key={key}>
      {textRenderer(
        <RawHtml value={children} />,
        {
          ...props,
          ...typographyProps,
        },
        key
      )}
    </React.Fragment>
    // <CustomTypography
    //   variant={variant}
    //   color={color}
    //   key={key}
    //   weight={weight}
    //   mb={margins ? mbMappings[variant as string] : undefined}
    //   {...props}
    // >
    //   <RawHtml value={children} />
    // </CustomTypography>
  )
}

function serializeImage(linkResolver: any, element: any, key: any) {
  const img = createElement("img", { src: element.url, alt: element.alt || "" })

  return createElement(
    "p",
    propsWithUniqueKey(
      { className: [element.label || "", "block-img"].join(" ") },
      key
    ),
    element.linkTo ? serializeLink(linkResolver, element, img, key) : img
  )
}

function serializeLabel(element: any, children: any, key: any) {
  const props = element.data
    ? Object.assign({}, { className: element.data.label })
    : {}
  return createElement("span", propsWithUniqueKey(props, key), children)
}

// function serializeEmbed(element: any, key: any) {
//   if (embeds[element.oembed.provider_name]) {
//     createScript(embeds[element.oembed.provider_name])
//   }

//   const className = `embed embed-${element.oembed.provider_name.toLowerCase()}`
//   const props = Object.assign(
//     {
//       "data-oembed": element.oembed.embed_url,
//       "data-oembed-type": element.oembed.type,
//       "data-oembed-provider": element.oembed.provider_name,
//       ref: (ref: any) => {
//         if (embeds[element.oembed.provider_name]) {
//           embeds[element.oembed.provider_name].load(ref)
//         }
//       },
//     },
//     element.label
//       ? { className: `${className} ${element.label}` }
//       : { className }
//   )

//   const embedHtml = createElement("div", {
//     dangerouslySetInnerHTML: { __html: element.oembed.html },
//   })

//   return createElement("div", propsWithUniqueKey(props, key), embedHtml)
// }

const normalizeTextProps = (
  typographyProps: TextProps,
  defaultVariant: TextVariant
): TextProps => ({
  ...typographyProps,
  ...{
    variant: typographyProps.variant ?? defaultVariant,
  },
})

export function serialize(
  textRenderer: TextRenderer,
  linkResolver: any,
  typographyProps: TextProps,
  elements: any,
  placeholders: Placeholders | undefined,
  type: any,
  element: any,
  content: any,
  children: any,
  index: any
) {
  if (elements?.[type]) {
    return serializeElement(
      elements[type],
      type,
      element,
      content,
      children,
      index
    )
  }
  switch (type) {
    case Elements.heading1:
      return serializeTypographyElement(
        textRenderer,
        normalizeTextProps(typographyProps, "h1"),
        element,
        children,
        index
      )
    case Elements.heading2:
      return serializeTypographyElement(
        textRenderer,
        normalizeTextProps(typographyProps, "h2"),
        element,
        children,
        index
      )
    case Elements.heading3:
      return serializeTypographyElement(
        textRenderer,
        normalizeTextProps(typographyProps, "h3"),
        element,
        children,
        index
      )
    case Elements.heading4:
      return serializeTypographyElement(
        textRenderer,
        normalizeTextProps(typographyProps, "h4"),
        element,
        children,
        index
      )
    case Elements.heading5:
      return serializeTypographyElement(
        textRenderer,
        normalizeTextProps(typographyProps, "h5"),
        element,
        children,
        index
      )
    case Elements.heading6:
      return serializeTypographyElement(
        textRenderer,
        normalizeTextProps(typographyProps, "h6"),
        element,
        children,
        index
      )
    case Elements.paragraph:
      return serializeTypographyElement(
        textRenderer,
        normalizeTextProps(typographyProps, "body1"),
        element,
        children,
        index
      )
    case Elements.preformatted:
      return serializeStandardTag("pre", element, children, index, placeholders)
    case Elements.strong:
      return serializeStandardTag(
        "strong",
        element,
        children,
        index,
        placeholders
      )
    case Elements.em:
      return serializeStandardTag("em", element, children, index, placeholders)
    case Elements.listItem:
      return serializeStandardTag("li", element, children, index, placeholders)
    case Elements.oListItem:
      return serializeStandardTag("li", element, children, index, placeholders)
    case Elements.list:
      return serializeStandardTag("ul", element, children, index, placeholders)
    case Elements.oList:
      return serializeStandardTag("ol", element, children, index, placeholders)
    case Elements.span:
      return serializeSpan(content, placeholders)
    case Elements.image:
      return serializeImage(linkResolver, element, index)
    // case Elements.embed:
    //   return serializeEmbed(element, index)
    case Elements.hyperlink:
      return serializeLink(linkResolver, element, children, index)
    case Elements.label:
      return serializeLabel(element, children, index)
    default:
      return <span key={index}>{children}</span>
  }
}
