import React from "react"
import { MuiRichTextRenderingProvider } from "../richText/provider"
import { MUIThemeProvider } from "../themes/provider"

interface Props {
  children: any
  themeId?: string
}

export const MuiProviders = ({ children, themeId }: Props) => {
  return (
    <MUIThemeProvider themeId={themeId} withBaseline>
      <MuiRichTextRenderingProvider>{children}</MuiRichTextRenderingProvider>
    </MUIThemeProvider>
  )
}
