import React from "react"
import { RichTextProcessors, RichTextRenderingProps } from "../../../common"
import { RawContent } from "./renderer"

interface Props {
  children: any
}

const PrismicRichTextProcessor = ({
  value,
  placeholders,
  className,
  ...typographyProps
}: RichTextRenderingProps) => {
  return (
    <RawContent
      value={value.raw ?? value}
      className={className}
      placeholders={placeholders}
      typographyProps={typographyProps}
    />
  )
}

export const PrismicRichTextProvider = ({ children }: Props) => {
  return (
    <RichTextProcessors.Provider
      value={{
        default: (props) => <PrismicRichTextProcessor {...props} />,
      }}
    >
      {children}
    </RichTextProcessors.Provider>
  )
}
