import React from "react"
import { TextRenderer } from "./types"

export interface TextRenderersContext {
  default: TextRenderer
}

const emptyTextRenderer: TextRenderer = () => {
  throw new Error("No text render has been defined")
}

export const TextRenderers = React.createContext<TextRenderersContext>({
  default: emptyTextRenderer,
})

export const useTextRenderers = () => React.useContext(TextRenderers)
export const useDefaultTextRenderer = () => useTextRenderers().default
