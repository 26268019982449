import React from "react"
import PrismicRichText from "prismic-richtext"
import {
  Placeholders,
  SitePages,
  SitePagesContext,
  TextProps,
  useDefaultTextRenderer,
} from "../../../common"
import { serialize } from "./serializer"

interface RawContentProps {
  value: any
  placeholders?: Placeholders
  elements?: any
  typographyProps?: TextProps
  className?: string
}

const defaultLinkResolver = (sitePages: SitePages, doc: any) => {
  try {
    return sitePages.getPagePath(doc.id)
  } catch (e) {
    console.error("Error resolving document url", doc)
    console.error(e)
  }
}

export const RawContent = ({
  value,
  typographyProps,
  placeholders,
  elements,
  className,
}: RawContentProps) => {
  const sitePages = React.useContext(SitePagesContext)
  const linkResolver = defaultLinkResolver.bind(null, sitePages)
  const textRenderer = useDefaultTextRenderer()
  return (
    <div className={className}>
      {PrismicRichText.serialize(
        value,
        serialize.bind(
          null,
          textRenderer,
          linkResolver,
          typographyProps,
          elements,
          placeholders
        )
      )}
    </div>
  )
}
