import React from "react"
import { TextRenderers } from "../../../common"
import MuiTextRenderer from "./renderer"

interface Props {
  children: any
}

export const MuiRichTextRenderingProvider = ({ children }: Props) => {
  return (
    <TextRenderers.Provider
      value={{
        default: (text, props, key) => (
          <MuiTextRenderer text={text} props={props} key={key} />
        ),
      }}
    >
      {children}
    </TextRenderers.Provider>
  )
}
