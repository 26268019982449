import { Theme } from "@material-ui/core"
import { useDefaultMobileBreakpoint } from "../../../common"

export const whenMobile = (theme: Theme) => {
  const breakpoint = useDefaultMobileBreakpoint()
  return theme.breakpoints.down(breakpoint)
}

export const whenDesktop = (theme: Theme) => {
  const breakpoint = useDefaultMobileBreakpoint()
  return theme.breakpoints.up(breakpoint)
}
