import React from "react"
import { Hidden } from "@material-ui/core"
import { useDefaultMobileBreakpoint } from "../../../common/layout/breakpoints"

interface Props {
  children: any
  implementation?: "css" | "js"
}

const DesktopOnly = ({ children, implementation }: Props) => {
  const breakpoint = useDefaultMobileBreakpoint()
  return (
    <Hidden
      implementation={implementation}
      xsDown={breakpoint === "xs"}
      smDown={breakpoint === "sm"}
      mdDown={breakpoint === "md"}
    >
      {children}
    </Hidden>
  )
}

DesktopOnly.defaultProps = {
  implementation: "css",
}

export default DesktopOnly
