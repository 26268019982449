const isEmptyRawValue = (value: any) =>
  value.length === 0 || (value.length === 1 && value[0].text === "")

const isEmptyHtmlValue = (value: string) => value === ""

export const isEmtyRichText = (value: any) => {
  if (!value) {
    return true
  }
  return (
    (value.raw && isEmptyRawValue(value.raw)) ||
    (value.html && isEmptyHtmlValue(value.html))
  )
}
