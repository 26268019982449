import { PathGenerator } from "../../../common/routing/types"

const createPageUid = (node: any) =>
  node.uid.toLowerCase() === "home" ? "" : node.uid

const getLocalePrefix = (node: any, siteSettings: any) => {
  if (!node.lang) {
    return undefined
  }

  const langSettings = siteSettings.data.allPrismicWebsiteSettings.nodes.find(
    (x: any) => x.lang === node.lang
  )
  return langSettings.data.locale_path
}

export const createPagePath = (
  node: any,
  siteSettings: any,
  pathPrefix?: string | PathGenerator
) => {
  const segments = []
  const localePrefix = getLocalePrefix(node, siteSettings)
  if (localePrefix) {
    segments.push(localePrefix)
  }

  if (pathPrefix) {
    if (typeof pathPrefix === "string") {
      segments.push(pathPrefix)
    } else {
      segments.push(pathPrefix(node))
    }
  }

  segments.push(createPageUid(node))

  return `/${segments.join("/")}`
}
