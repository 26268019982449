import React from "react"
import { PageContext } from "./types"

export const CurrentPageContext = React.createContext<PageContext>({
  id: "",
  lang: "",
  alternatePages: [],
})

export const usePageContext = () => React.useContext(CurrentPageContext)
export const usePageLanguage = () => usePageContext().lang
