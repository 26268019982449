import { resolve } from "path"
import { PageTemplateParameters } from "../../../common/routing/types"
import { createPagePath } from "./paths"

export const createPrismicPage = (
  boundActionCreators: any,
  templateParameters: PageTemplateParameters,
  siteSettings: any,
  node: any
) => {
  const { createPage } = boundActionCreators
  const path = createPagePath(node, siteSettings, templateParameters.pathPrefix)
  console.log(`Generating page -> ${path} -> ${node.lang}`)

  return createPage({
    path,
    component: resolve(templateParameters.component),
    context: {
      id: node.prismicId,
      documentId: node.id,
      uid: node.uid,
      type: node.type,
      locale: node.lang,
      alternateLanguages: node.alternate_languages,
      seo: {
        noIndex: node.data.seo_no_index,
        noFollow: node.data.seo_no_follow,
        priority: node.data.seo_priority,
      },
    },
  })
}
