import React from "react"
import { navigate } from "gatsby"
import { usePrismicPreview } from "gatsby-source-prismic"
import { SitePages, SitePagesContext } from "../../../../common/context/pages"
import { WaterLoader } from "../../../../common/components"
import PrismicRepoSettingsContext from "../../context/settings"
import { PrismicScript } from "../../scripts"
import { savePreviewData } from "../utils/store"

const getPrismicId = (previewData: any) => {
  for (const key in previewData) {
    if (previewData[key].prismicId !== undefined) {
      return previewData[key].prismicId
    }
  }
}

const getTargetPagePath = (previewData: any, sitePages: SitePages) => {
  const prismicId = getPrismicId(previewData)
  if (!prismicId) {
    return "/"
  }

  return sitePages.getPagePath(prismicId) ?? "/"
}

const PreviewLoader = () => {
  const sitePages = React.useContext(SitePagesContext)
  const settings = React.useContext(PrismicRepoSettingsContext)
  const { isPreview, previewData, path } = usePrismicPreview({
    repositoryName: settings.repoName,
  })

  React.useEffect(() => {
    if (!isPreview) {
      return
    }

    if (previewData) {
      const target = getTargetPagePath(previewData, sitePages)
      savePreviewData({
        path: target,
        data: previewData,
      })
      navigate(target)
    }
  }, [isPreview, previewData, path])

  if (isPreview === false) {
    return <div>Not a preview!</div>
  }

  return (
    <>
      <WaterLoader />
      <PrismicScript />
    </>
  )
}

export default PreviewLoader
