import { Theme } from "@material-ui/core"
import { ThemeColor, ThemeColorVariant } from "../../../common"

export const getMuiPrimaryColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.primary?.main
    case "dark":
      return theme.palette.primary?.dark
    case "light":
      return theme.palette.primary?.light
  }
}

export const getMuiSecondaryColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.secondary?.main
    case "dark":
      return theme.palette.secondary?.dark
    case "light":
      return theme.palette.secondary?.light
  }
}

export const getMuiInfoColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.info?.main
    case "dark":
      return theme.palette.info?.dark
    case "light":
      return theme.palette.info?.light
  }
}

export const getMuiSuccessColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.success?.main
    case "dark":
      return theme.palette.success?.dark
    case "light":
      return theme.palette.success?.light
  }
}

export const getMuiWarningColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.warning?.main
    case "dark":
      return theme.palette.warning?.dark
    case "light":
      return theme.palette.warning?.light
  }
}

export const getMuiErrorColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.error?.main
    case "dark":
      return theme.palette.error?.dark
    case "light":
      return theme.palette.error?.light
  }
}

export const getMuiColor = (
  theme: Theme,
  color: ThemeColor,
  colorVariant = "main" as ThemeColorVariant
) => {
  switch (color) {
    case "primary":
      return getMuiPrimaryColor(theme, colorVariant)
    case "secondary":
      return getMuiSecondaryColor(theme, colorVariant)
    case "info":
      return getMuiInfoColor(theme, colorVariant)
    case "success":
      return getMuiSuccessColor(theme, colorVariant)
    case "warning":
      return getMuiWarningColor(theme, colorVariant)
    case "error":
      return getMuiErrorColor(theme, colorVariant)
    case "neutral":
      return theme.palette.action?.active
    case "disabled":
      return theme.palette.action?.disabled
    case "white":
      return theme.palette.common.white
    case "black":
      return theme.palette.common.black
    case "inherit":
      return "inherit"
    case "transparent":
      return "rgba(0,0,0,0)"
  }
}
