import { ThemeBreakpoint } from "./types"

export const getHigherBreakpoint = (value: ThemeBreakpoint) => {
  switch (value) {
    case "xs":
      return "sm"
    case "sm":
      return "md"
    case "md":
      return "lg"
    case "lg":
      return "xl"
    case "xl":
      throw Error("Cannot create higher breakpoint than xl")
  }
}

export const getLowerBreakpoint = (value: ThemeBreakpoint) => {
  switch (value) {
    case "xl":
      return "lg"
    case "lg":
      return "md"
    case "md":
      return "sm"
    case "sm":
      return "xs"
    case "xs":
      throw Error("Cannot create lower breakpoint than xs")
  }
}
