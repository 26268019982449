import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles"
import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography"
import { getHigherBreakpoint } from "../../../common"
import {
  AppActionColors,
  AppButtonStyles,
  AppFontColors,
  AppFontVariant,
  AppPaletteColor,
  AppTheme,
} from "../../../common/context/theme/types"

const NONE_IMPORTANT = "none !important"
const BLACK = "#000"

const fontWeight = (value: string | undefined) =>
  value ? parseInt(value.trim()) : undefined

const paletteColor = (color: AppPaletteColor | undefined) =>
  color?.main
    ? {
        main: color.main,
        light: color.light,
        dark: color.dark,
      }
    : undefined

const textColors = (textColors: AppFontColors) => ({
  primary: textColors.primary ?? BLACK,
  secondary: textColors.secondary,
  disabled: textColors.disabled,
  hint: textColors.hint,
})

const actionColors = (actionColors: AppActionColors | undefined) =>
  actionColors
    ? {
        active: actionColors.active,
        disabled: actionColors.disabled,
        focus: actionColors.focus,
        hover: actionColors.hover,
        selected: actionColors.selected,
      }
    : undefined

const fontTypography = (
  font: AppFontVariant | undefined,
  mobileFont: AppFontVariant | undefined,
  mobileBreakpoint: number
): TypographyStyleOptions | undefined =>
  font
    ? {
        fontSize: font.size,
        fontWeight: fontWeight(font.weight),
        lineHeight: font.lineHeight,
        letterSpacing: font.letterSpacing,

        [`@media (max-width:${mobileBreakpoint}px)`]: {
          fontSize: mobileFont?.size,
          fontWeight: fontWeight(mobileFont?.weight),
          lineHeight: mobileFont?.lineHeight,
          letterSpacing: mobileFont?.letterSpacing,
        },
      }
    : undefined

const boxShadow = (theme: AppTheme, override?: string) =>
  override ?? theme.styles.shadows.disabled
    ? NONE_IMPORTANT
    : theme.styles.shadows.style

const buttonStyles = (
  theme: AppTheme,
  styles: AppButtonStyles | undefined,
  defaults: AppButtonStyles
) => ({
  boxShadow: styles?.boxShadow ?? defaults.boxShadow ?? boxShadow(theme),
  borderWidth: styles?.borderWidth ?? defaults.borderWidth,
  fontWeight: fontWeight(styles?.fontWeight) ?? fontWeight(defaults.fontWeight),
  letterSpacing: styles?.letterSpacing ?? defaults.letterSpacing,
  paddingRight: styles?.padding?.right ?? defaults.padding?.right,
  paddingLeft: styles?.padding?.left ?? defaults.padding?.left,
  paddingTop: styles?.padding?.top ?? defaults.padding?.top,
  paddingBottom: styles?.padding?.bottom ?? defaults.padding?.bottom,
})

const DEFAULT_BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const mobileBreakpoint = (variables: AppTheme) =>
  DEFAULT_BREAKPOINTS[
    getHigherBreakpoint(variables.breakpoints.mobileBreakpoint)
  ]

export const buildTheme = (
  variables: AppTheme,
  options?: ThemeOptions,
  ...args: any[]
) =>
  createMuiTheme({
    breakpoints: {
      values: DEFAULT_BREAKPOINTS,
    },
    palette: {
      primary: paletteColor(variables.palette.primary),
      secondary: paletteColor(variables.palette.secondary),
      info: paletteColor(variables.palette.info),
      success: paletteColor(variables.palette.success),
      warning: paletteColor(variables.palette.warn),
      error: paletteColor(variables.palette.error),
      text: textColors(variables.font.styles.textColors),
      action: actionColors(variables.palette.action),
      background: {
        default: variables.background.default?.color ?? "#fff",
      },
    },
    shape: {
      borderRadius: variables.styles.borders.borderRadius.default,
    },
    typography: {
      fontFamily: variables.font.styles.defaults.fontFamily,
      h1: fontTypography(
        variables.font.typography.h1,
        variables.font.typographyMobile?.h1,
        mobileBreakpoint(variables)
      ),
      h2: fontTypography(
        variables.font.typography.h2,
        variables.font.typographyMobile?.h2,
        mobileBreakpoint(variables)
      ),
      h3: fontTypography(
        variables.font.typography.h3,
        variables.font.typographyMobile?.h3,
        mobileBreakpoint(variables)
      ),
      h4: fontTypography(
        variables.font.typography.h4,
        variables.font.typographyMobile?.h4,
        mobileBreakpoint(variables)
      ),
      h5: fontTypography(
        variables.font.typography.h5,
        variables.font.typographyMobile?.h5,
        mobileBreakpoint(variables)
      ),
      h6: fontTypography(
        variables.font.typography.h6,
        variables.font.typographyMobile?.h6,
        mobileBreakpoint(variables)
      ),
      subtitle1: fontTypography(
        variables.font.typography.subtitle1,
        variables.font.typographyMobile?.subtitle1,
        mobileBreakpoint(variables)
      ),
      subtitle2: fontTypography(
        variables.font.typography.subtitle2,
        variables.font.typographyMobile?.subtitle2,
        mobileBreakpoint(variables)
      ),
      body1: fontTypography(
        variables.font.typography.body1,
        variables.font.typographyMobile?.body1,
        mobileBreakpoint(variables)
      ),
      body2: fontTypography(
        variables.font.typography.body2,
        variables.font.typographyMobile?.body2,
        mobileBreakpoint(variables)
      ),
      overline: fontTypography(
        variables.font.typography.overline,
        variables.font.typographyMobile?.overline,
        mobileBreakpoint(variables)
      ),
    },
    overrides: {
      MuiAppBar: {
        root: {
          boxShadow: boxShadow(variables),
        },
      },
      MuiFab: {
        root: {
          boxShadow: boxShadow(variables),
        },
      },
      MuiButtonBase: {
        root: {
          boxShadow: boxShadow(variables),
        },
      },
      MuiOutlinedInput: {
        root: {
          "& fieldset": {
            borderWidth: "1px",
          },
        },
      },
      MuiButton: {
        root: buttonStyles(
          variables,
          undefined,
          variables.components.button.default
        ),
        contained: buttonStyles(
          variables,
          undefined,
          variables.components.button.default
        ),
        outlined: buttonStyles(
          variables,
          undefined,
          variables.components.button.default
        ),
        outlinedPrimary: {
          borderColor: variables.palette.primary?.main,
        },
        outlinedSecondary: {
          borderColor: variables.palette.secondary?.main,
        },
      },
    },
    ...options,
    ...args,
  })
