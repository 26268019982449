import ReactDOM from "react-dom"
import "animate.css/animate.css"
import "./src/styles/font.css"
import "./src/styles/reset.css"
import 'swiper/swiper-bundle.min.css'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}

export const registerServiceWorker = () => true

export { wrapRootElement } from "./src/wrapRoot"
