const defaultIdLength = 6

export const randomId = (length: number = defaultIdLength) => {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const trimStart = (str: string, value: string) =>
  str.startsWith(value) ? str.substring(value.length) : str

export const trimEnd = (str: string, value: string) =>
  str.endsWith(value) ? str.substring(0, str.length - value.length) : str

export const trim = (str: string, value: string) =>
  trimStart(trimEnd(str, value), value)

export const capitalize = (s?: string) => {
  return s ? s.charAt(0).toUpperCase() + s.slice(1) : undefined
}

export const capitalizeWords = (s?: string) => {
  return s ? s.split(" ").map(capitalize).join(" ") : undefined
}
