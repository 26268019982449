import React from "react"
import { ThemeProvider, CssBaseline } from "@material-ui/core"
import { buildTheme } from "./builder"
import { useAppTheme, useDefaultAppTheme } from "../../../common/context/theme"

interface Props {
  children: React.ReactNode
  withBaseline: boolean
  themeId?: string
}

const MUIThemeProvider = ({ children, withBaseline, themeId }: Props) => {
  const defaultTheme = useDefaultAppTheme()
  const customTheme = themeId ? useAppTheme(themeId) : undefined

  return (
    <ThemeProvider theme={buildTheme(customTheme ?? defaultTheme)}>
      {withBaseline === true ? <CssBaseline /> : undefined}
      {children}
    </ThemeProvider>
  )
}

MUIThemeProvider.defaultProps = {
  withBaseline: false,
}

export { MUIThemeProvider }
