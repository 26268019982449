import React from "react"
import { SitePagesProvider } from "../context/pages"

interface Props {
  children: any
}

const RootProviders = ({ children }: Props) => {
  return <SitePagesProvider>{children}</SitePagesProvider>
}

export default RootProviders
