import React from "react"
import { useLogosQuery } from "./queries"

export interface WebsiteLogos {
  logoPrimary: string
  logoSecondary: string
  logoWhite: string
  favicon: string
}

export const LogosContext = React.createContext<WebsiteLogos>({
  favicon: "",
  logoPrimary: "",
  logoSecondary: "",
  logoWhite: "",
})

interface Props {
  children: any
}

export const PrismicLogosProvider = ({ children }: Props) => {
  const logos = useLogosQuery()
  return (
    <LogosContext.Provider
      value={{
        favicon: logos.prismicWebsiteLogos?.data?.favicon?.url ?? "",
        logoPrimary: logos.prismicWebsiteLogos?.data?.logo_primary?.url ?? "",
        logoSecondary:
          logos.prismicWebsiteLogos?.data?.logo_secondary?.url ?? "",
        logoWhite: logos.prismicWebsiteLogos?.data?.logo_white?.url ?? "",
      }}
    >
      {children}
    </LogosContext.Provider>
  )
}
