import React from "react"
import { TextNode, TextProps } from "../../../common/fields"
import CustomTypography from "../typography"

interface Props {
  text: TextNode
  props: TextProps
  innerKey?: any
}

const MuiTextRenderer = ({ text, props }: Props) => {
  return <CustomTypography {...props}>{text}</CustomTypography>
}

export default MuiTextRenderer
