import { graphql, useStaticQuery } from "gatsby"
import { WebsiteLogosQuery } from "../../../__generated__/WebsiteLogosQuery"

export const useLogosQuery = () =>
  useStaticQuery<WebsiteLogosQuery>(graphql`
    query WebsiteLogosQuery {
      prismicWebsiteLogos {
        data {
          logo_primary {
            url
          }
          logo_secondary {
            url
          }
          logo_white {
            url
          }
          favicon {
            url
          }
        }
      }
    }
  `)
